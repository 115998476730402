<template>
    <router-view></router-view>
</template>

<script>
    export default {
        name:'Cat',
    }
</script>

<style lang="scss" scoped>

</style>